'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  gap: 4px;\n'], ['\n  gap: 4px;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  border-radius: 4px;\n  border: 1px solid ', ';\n  text-align: center;\n  cursor: pointer;\n  border-radius: 8px;\n'], ['\n  border-radius: 4px;\n  border: 1px solid ', ';\n  text-align: center;\n  cursor: pointer;\n  border-radius: 8px;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _util = require('./util');

var _ramda = require('ramda');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var IncreamentBase = function (_React$Component) {
  _inherits(IncreamentBase, _React$Component);

  function IncreamentBase(props) {
    _classCallCheck(this, IncreamentBase);

    var _this = _possibleConstructorReturn(this, (IncreamentBase.__proto__ || Object.getPrototypeOf(IncreamentBase)).call(this, props));

    _this.decrement = function () {
      if (_this.state.val <= _this.props.min) {
        return;
      }

      _this.setState(function (prevState) {
        return { val: prevState.val - 1 };
      }, function () {
        return _this.onChange();
      });
    };

    _this.increment = function () {
      if (_this.state.val >= _this.props.max) {
        return;
      }

      _this.setState(function (prevState) {
        return { val: prevState.val + 1 };
      }, function () {
        return _this.onChange();
      });
    };

    _this.onBlur = function (e) {
      var _this$props = _this.props,
          max = _this$props.max,
          min = _this$props.min;

      var value = +e.target.value;
      value = isNaN(value) ? min || 0 : value;
      value = value > max ? max : value;
      value = value < min ? min : value;

      _this.setState({ val: value }, function () {
        return _this.onChange();
      });
    };

    _this.onInput = function (e) {
      var value = e.target.value;
      if (value === '-' || value === '') {
        _this.setState({ val: value });
        return;
      }

      value = +value;
      if (!isNaN(value)) {
        _this.setState({ val: value });
      }
    };

    _this.handleKeyDown = function (event) {
      if (event.key === 'ArrowUp') {
        _this.increment();
        event.preventDefault();
      } else if (event.key === 'ArrowDown') {
        _this.decrement();
        event.preventDefault();
      }
    };

    _this.onChange = function () {
      var onChange = _this.props.onChange;

      onChange && onChange(_this.state.val);
    };

    _this.state = {
      val: props.value || 0
    };
    return _this;
  }

  _createClass(IncreamentBase, [{
    key: 'render',
    value: function render() {
      var val = this.state.val;

      var props = (0, _ramda.omit)(['className', 'onChange'], this.props);
      return React.createElement(
        StyledFlexWrapper,
        null,
        React.createElement(StyledButton, _extends({}, props, {
          children: '-',
          bg: 'white',
          onClick: this.decrement
        })),
        React.createElement('input', _extends({}, props, {
          className: this.props.className,
          value: val,
          onInput: this.onInput,
          onBlur: this.onBlur,
          onKeyDown: this.handleKeyDown
        })),
        React.createElement(StyledButton, _extends({}, props, {
          children: '+',
          bg: 'white',
          onClick: this.increment
        }))
      );
    }
  }]);

  return IncreamentBase;
}(React.Component);

var StyledFlexWrapper = (0, _styledComponents2.default)(_.Flex)(_templateObject);

var StyledButton = (0, _styledComponents2.default)(_.Box)(_templateObject2, function (props) {
  return (0, _util.color)(props)('border');
});

exports.default = IncreamentBase;