'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRouterDom = require('react-router-dom');

var _createBrowserHistory = require('history/createBrowserHistory');

var _createBrowserHistory2 = _interopRequireDefault(_createBrowserHistory);

var _withListener = require('./withListener');

var _withListener2 = _interopRequireDefault(_withListener);

var _invariant = require('invariant');

var _invariant2 = _interopRequireDefault(_invariant);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

exports.default = function (router, isLoggedIn, ComponentRoot) {
  var _onStateChanged = null;
  var history = (0, _createBrowserHistory2.default)();
  var routes = router.routes,
      loginPath = router.loginPath,
      basePath = router.basePath;


  var hasLoginPath = loginPath && routes.find(function (route) {
    return route.path === loginPath;
  });

  (0, _invariant2.default)(hasLoginPath, 'You are providing \'loginPath\', please make sure the route is exist in your routes definition');

  var PrivateRoute = function PrivateRoute(props) {
    return isLoggedIn ? _react2.default.createElement(_reactRouterDom.Route, props) : _react2.default.createElement(_reactRouterDom.Route, {
      render: function render(currentProps) {
        return _react2.default.createElement(_reactRouterDom.Redirect, {
          to: {
            pathname: loginPath,
            state: {
              from: '' + currentProps.location.pathname + currentProps.location.search
            }
          }
        });
      }
    });
  };

  var RouteWrapper = function (_Component) {
    _inherits(RouteWrapper, _Component);

    function RouteWrapper() {
      _classCallCheck(this, RouteWrapper);

      var _this = _possibleConstructorReturn(this, (RouteWrapper.__proto__ || Object.getPrototypeOf(RouteWrapper)).call(this));

      _this.handleUpdateRoute = _this.handleUpdateRoute.bind(_this);
      return _this;
    }

    _createClass(RouteWrapper, [{
      key: 'handleUpdateRoute',
      value: function handleUpdateRoute(route) {
        _onStateChanged(route);
      }
    }, {
      key: 'renderRoute',
      value: function renderRoute(route, i) {
        if (hasLoginPath && route.private) {
          return _react2.default.createElement(PrivateRoute, {
            exact: route.exact || false,
            key: route.path || i,
            path: route.path,
            component: (0, _withListener2.default)(route.component, {
              routes: routes,
              onUpdate: this.handleUpdateRoute
            })
          });
        }
        return _react2.default.createElement(_reactRouterDom.Route, {
          exact: route.exact || false,
          key: route.path || i,
          path: route.path,
          component: (0, _withListener2.default)(route.component, {
            routes: routes,
            onUpdate: this.handleUpdateRoute
          })
        });
      }
    }, {
      key: 'render',
      value: function render() {
        var _this2 = this;

        var isRootComponentValid = typeof ComponentRoot === 'function';

        var switchNode = _react2.default.createElement(
          _reactRouterDom.Switch,
          null,
          routes.map(function (route, i) {
            return _this2.renderRoute(route, i);
          })
        );

        var renderRoute = function renderRoute(route) {
          return isRootComponentValid ? _react2.default.createElement(
            ComponentRoot,
            null,
            route
          ) : route;
        };

        if (basePath) {
          return _react2.default.createElement(
            _reactRouterDom.BrowserRouter,
            { basename: basePath },
            switchNode
          );
        }
        return _react2.default.createElement(
          _reactRouterDom.Router,
          { history: history },
          renderRoute(switchNode)
        );
      }
    }], [{
      key: 'onStateChanged',
      value: function onStateChanged(callback) {
        _onStateChanged = callback;
      }
    }]);

    return RouteWrapper;
  }(_react.Component);

  return RouteWrapper;
};