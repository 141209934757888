var Cookies = require('cookies-js');

var getStorage = function getStorage() {
  return window.__GLOBAL__ && window.__GLOBAL__.tokenStorage;
}; // you can override how to store the token by set `window.__GLOBAL__.tokenStorage` object in your project

var TOKEN_KEYS = {
  token: 'token',
  refreshToken: 'refreshToken'
};

export default {
  saveToken: function saveToken(payload) {
    var resolvedRefreshToken = payload.refreshToken || payload.refresh_token;
    var storage = getStorage();
    if (storage) {
      storage.set(TOKEN_KEYS.token, payload.token);
      storage.set(TOKEN_KEYS.refreshToken, payload.refreshToken);
    } else {
      Cookies.set(TOKEN_KEYS.token, payload.token, { expires: Infinity });
      Cookies.set(TOKEN_KEYS.refreshToken, resolvedRefreshToken, {
        expires: Infinity
      });
    }
  },
  removeToken: function removeToken() {
    var storage = getStorage();
    if (storage) {
      storage.remove(TOKEN_KEYS.token);
      storage.remove(TOKEN_KEYS.refreshToken);
    } else {
      Cookies.expire(TOKEN_KEYS.token);
      Cookies.expire(TOKEN_KEYS.refreshToken);
    }
  },
  getToken: function getToken() {
    var storage = getStorage();
    if (storage) {
      return {
        token: storage.get(TOKEN_KEYS.token),
        refreshToken: storage.get(TOKEN_KEYS.refreshToken)
      };
    }

    return {
      token: Cookies.get(TOKEN_KEYS.token),
      refreshToken: Cookies.get(TOKEN_KEYS.refreshToken)
    };
  }
};